import React, { useState, useEffect } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  multilanguageListingTitle,
  multilanguageListingDescription,
} from '../../util/translationUtils';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { GoogleAnalyticsHandler } from '../../analytics/handlers';
import getCountryCodes from '../../translations/countryCodes';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  AvatarSmall,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  fetchRatesOffer,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import { googleAnalyticsEnabled } from '../../config/configAnalytics';
import { structuredVariants } from '../../util/genericHelpers.js';
import OfferModal from './OfferForm/OfferModal.js';
import { createResourceLocatorString } from '../../util/routes.js';
import { MOBILE_MODAL_BREAKPOINT, OPEN_OFFER_MODAL } from '../../util/enums.js';
import { withViewport } from '../../util/uiHelpers.js';
import { languagesHelper as language } from '../../helpers/languages';
import { currenciesHelper as currency } from '../../helpers/currencies';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isOffersModalOpen, onToggleOffersModal] = useState(false);

  useEffect(() => {
    // Matomo
    if (typeof _paq !== 'undefined') {
      _paq.push(['trackEvent', 'Shopping', 'ShopListingView', props.params.id]);
    }
    const analyticsHandler = new GoogleAnalyticsHandler();
    if (googleAnalyticsEnabled == true) {
      analyticsHandler.trackCustomEvent('ShopListingView');
    }
  }, [props.params.id]);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    sendInquiryInProgress,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    viewport,
    conversionRates,
    conversionRatesOffer,
    onFetchRatesOffer,
  } = props;

  const isMobileLayout = viewport.width <= MOBILE_MODAL_BREAKPOINT;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup

  const listingConfig = listingConfigProp || config.listing;

  const variantListings = structuredVariants(props.variantListings, listingConfig?.listingFields);

  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const brandName = publicData.brandChild;
  const size = publicData.size;

  let currentLocale = language.get();

  const customListingTitle = multilanguageListingTitle(currentListing, currentLocale);

  const richTitle = (
    <span>
      {richText(customListingTitle, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = selectedVariant?.author || (authorAvailable ? currentListing.author : null);
  const htmlDescription = selectedVariant
    ? multilanguageListingDescription(selectedVariant, currentLocale)
    : multilanguageListingDescription(currentListing, currentLocale);

  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const countryOptions = getCountryCodes().map(country => (
    <option key={country.code} value={country.code}>
      {country.name}
    </option>
  ));

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });

  const listingCurrency = price?.currency;
  let currentCurrency = !!conversionRates ? currency.get() : listingCurrency;
  const conversionRate = conversionRates?.[currentCurrency] || 1;

  const providerCurrency =
    currentListing.author.attributes.profile.publicData?.currency || listingCurrency;

  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    selectedVariant,
    onInitializeCardPaymentData,
    currentCurrency,
    listingCurrency,
    providerCurrency,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title: customListingTitle, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            <SectionGallery
              selectedVariant={selectedVariant}
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />

            <div className={css.orderColumnForProductLayoutMobile}>
              <OrderPanel
                className={css.productOrderPanelMobile}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                onToggleOffersModal={boolean => {
                  if (isAuthenticated) {
                    onToggleOffersModal(boolean);
                    onFetchRatesOffer(currentCurrency);
                  } else {
                    history.push(
                      createResourceLocatorString('SignupPage', routeConfiguration, {}, {}),
                      { from: `${location.pathname}${location.search}${OPEN_OFFER_MODAL}` }
                    );
                  }
                }}
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                }
                title={
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                }
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                variantListings={variantListings}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
                conversionRate={conversionRate}
                currentCurrency={currentCurrency}
              />
            </div>

            <div className={css.detailsSectionMobile}>
              <SectionDetailsMaybe
                publicData={publicData}
                metadata={metadata}
                listingConfig={listingConfig}
                selectedVariant={selectedVariant}
                intl={intl}
              />
              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, enumOptions, scope = 'public' } = config;
                const value =
                  scope === 'public'
                    ? publicData[key]
                    : scope === 'metadata'
                    ? metadata[key]
                    : null;
                const hasValue = value !== null;
                return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [...pickedElements]
                  : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                      ...pickedElements,
                      <SectionTextMaybe
                        key={key}
                        heading={config?.showConfig?.label}
                        text={value}
                      />,
                    ]
                  : pickedElements;
              }, [])}
              <SectionTextMaybe text={htmlDescription} showAsIngress />
            </div>
            <div className={css.detailsSectionDesktop}>
              <SectionDetailsMaybe
                publicData={publicData}
                metadata={metadata}
                listingConfig={listingConfig}
                selectedVariant={selectedVariant}
                intl={intl}
              />
              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, enumOptions, scope = 'public' } = config;
                const value =
                  scope === 'public'
                    ? publicData[key]
                    : scope === 'metadata'
                    ? metadata[key]
                    : null;
                const hasValue = value !== null;
                return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [...pickedElements]
                  : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                      ...pickedElements,
                      <SectionTextMaybe
                        key={key}
                        heading={config?.showConfig?.label}
                        text={value}
                      />,
                    ]
                  : pickedElements;
              }, [])}
            </div>

            <SectionTextMaybe text={htmlDescription} showAsIngress />
          </div>
          <div className={css.orderColumnForProductLayout}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              currentUser={currentUser}
              sendInquiryInProgress={sendInquiryInProgress}
              isOwnListing={isOwnListing}
              isMobileLayout={isMobileLayout}
              onSubmit={handleOrderSubmit}
              onSendInquiry={onSendInquiry}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
              onToggleOffersModal={boolean => {
                if (isAuthenticated) {
                  onToggleOffersModal(boolean);
                  onFetchRatesOffer(currentCurrency);
                } else {
                  history.push(
                    createResourceLocatorString('SignupPage', routeConfiguration, {}, {}),
                    { from: `${location.pathname}${location.search}${OPEN_OFFER_MODAL}` }
                  );
                }
              }}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              variantListings={variantListings}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              conversionRate={conversionRate}
              currentCurrency={currentCurrency}
            />
            <SectionTextMaybe text={htmlDescription} showAsIngress />
            <OfferModal
              config={config}
              params={params}
              location={location}
              history={history}
              intl={intl}
              listingBaseParams={`${location.pathname}${location.search}`}
              price={selectedVariant?.price}
              selectedVariant={selectedVariant}
              routeConfiguration={routeConfiguration}
              isOffersModalOpen={isOffersModalOpen}
              onOpenOffersModal={onToggleOffersModal}
              onSendInquiry={onSendInquiry}
              onManageDisableScrolling={onManageDisableScrolling}
              currentUser={currentUser}
              sendInquiryInProgress={sendInquiryInProgress}
              authorDisplayName={authorDisplayName}
              listingTitle={customListingTitle}
              listing={currentListing}
              conversionRate={conversionRate}
              currentCurrency={currentCurrency}
              providerCurrency={providerCurrency}
              conversionRatesOffer={conversionRatesOffer}
            />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    variantListingIds,
    conversionRates,
    conversionRatesOffer,
  } = state.ListingPage;

  const { currentUser } = state.user;

  const variantListings = getListingsById(state, variantListingIds);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    monthlyTimeSlots,
    lineItems,
    variantListings,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    conversionRates,
    conversionRatesOffer,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message, offerParams) =>
    dispatch(sendInquiry(listing, message, offerParams)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),

  onFetchRatesOffer: currency => dispatch(fetchRatesOffer(currency)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(EnhancedListingPage);

export default ListingPage;
